import React, { useEffect } from 'react';
import { useNavigate, Routes, Route } from "react-router-dom";
import AdminSidebar from './AdminSidebar';
import Courses from './Courses';
import AddCourse from './AddCourse';
import AdminCourseEnq from './AdminCourseEnq';
import AdminCustomer from './AdminCustomer';
import AdminCorporate from './AdminCorporate';
import AdminHire from './AdminHire';
import AdminFranchise from './AdminFranchise';
import AdminCertificate from './AdminCertificate';
import AddIndemand from './AddIndemand';
import "../assets/styles/style.css";
import PageNotFound from "../components/PageNotFound";
import Dashboard from './Dashboard';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component

function Admin() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/adminlogin'); // Redirect to login if not authenticated
    }
  }, [navigate]);

  return (
    <div className='admin-main'>
      <div>
        <AdminSidebar />
      </div>
      <div className='scrolldiv'>
        <Routes>
          <Route
            path='courses'
            element={
              <ProtectedRoute>
                <Courses />
              </ProtectedRoute>
            }
          />
          <Route
            path='addcourse'
            element={
              <ProtectedRoute>
                <AddCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path='add-indemand'
            element={
              <ProtectedRoute>
                <AddIndemand />
              </ProtectedRoute>
            }
          />
          <Route
            path='viewcourseenq'
            element={
              <ProtectedRoute>
                <AdminCourseEnq />
              </ProtectedRoute>
            }
          />
          <Route
            path='viewcustomer'
            element={
              <ProtectedRoute>
                <AdminCustomer />
              </ProtectedRoute>
            }
          />
          <Route
            path='viewcorporate'
            element={
              <ProtectedRoute>
                <AdminCorporate />
              </ProtectedRoute>
            }
          />
          <Route
            path='viewhire'
            element={
              <ProtectedRoute>
                <AdminHire />
              </ProtectedRoute>
            }
          />
          <Route
            path='viewfranchise'
            element={
              <ProtectedRoute>
                <AdminFranchise />
              </ProtectedRoute>
            }
          />
          <Route
            path='addcertificate'
            element={
              <ProtectedRoute>
                <AdminCertificate />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default Admin;
