import React from 'react';
import "../assets/styles/style.css"
function PageNotFound() {
  return (
    <>
    <div className="page-not-found">
      <div className="content-container">
        <h1>404</h1>
        <p>Oops! The page you're looking for can't be found.</p>
        <a href="/" className="home-link">Return to Home</a>
      </div>
     
    </div>
  
    </>
  );
}

export default PageNotFound;
