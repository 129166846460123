import React from 'react'
import TopTechnologies from './TopTechnologies'
import "../assets/styles/style.css"
function Programs() {
  return (
    <div>
      <div className="tech-main">
      <TopTechnologies/>
      </div>
    </div>
  )
}

export default Programs
